<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <div class="col-md-4">
          <h4><i class="nav-icon icon-layers"></i> Project Budget</h4>
          <hr>
          <h5>Select Project</h5>
          <select class="select-account form-control" style="height: 42px;" name="editprojectid" @change="getBudget()" v-model="projectid">
            <option value="0" disabled selected>Select Project</option>
            <option for v-for="option in projectData"  v-bind:value="option">{{option.projectname}}</option>
          </select>
        </div>
      </b-row>
      <b-row v-if="projectid && categories.length === 0">
        <div class="mt-3 col-md-12">
          <h6>Please add categories to project.</h6>
        </div>
      </b-row>

  <div>
    <label for="budget-datepicker"><b>Choose a Month</b></label>
    <vue-monthly-picker
 v-model="month" :monthLabels="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
 placeHolder="No date selected" dateFormat="YYYY/MM">
</vue-monthly-picker><br />
<b-button variant="danger" @click="month = null">Remove month<i style="margin-left:10px;" class="fa fa-trash"></i></b-button>
</div>
      <div class="table-responsive">
      <form @submit.prevent="addBudgets('create')" data-vv-scope="create">
        <table v-if="categories.length > 0" class="mt-4 table">
        <thead>
          <th>Date</th>
          <th v-for="(cat, index) in categories">
            {{cat.category}}
          </th>
          <th></th>
        </thead>

        <tbody>
          <tr>
          <td>{{month ? month : nextMonth | moment('MMM YYYY')}}</td>
            <td v-for="(cat, index) in categories">
                <input v-validate="'required|decimal:2'" class="form-control" :name="'create.catinputs' + index" v-model="catinputs[index]" type="number" placeholder="Budget">

                <i v-show="errors.has('create.catinputs' + index)" class="mt-2 fa fa-exclamation-triangle"></i><span v-show="errors.has('create.catinputs' + index)" class="help mt-2 is-danger">The budget number is required.</span>

                <input v-model="cat.pcid" type="hidden" placeholder="Budget">
            </td>
            <td v-if="budgets">
              <b-button type="submit" variant="success">
                <i class="fa fa-plus"></i> Add Month
              </b-button>
            </td>
          </tr>

          <!-- <tr style="height: 20px;"></tr> -->
          <!-- {{categories}} -->
          <tr v-for="(budget, index) in budgets">
            <td>{{budget[0].date_index | moment("MMM YYYY")}}</td>
            </td>
            <td class="hover-mouse" v-for="(cat, i) in categories">
              <div v-b-modal.editBudget @click="sendInfo(budget[i])" v-if="budget[i]">
                <div v-if="cat">
                  <div v-if="cat.pcid === budget[i].pcid">
                    <strong>${{formatPrice(budget[i].budget)}} <i class="fa fa-edit"></i></strong>
                  </div>
                </div>
              </div>
              <div v-b-modal.addBudget @click="sendAddInfo(index, cat.pcid)" v-else>
                <div>
                  <div>
                    <strong><i class="fa fa-plus"></i> {{budget[index]}} {{index}}</strong>
                   </div>
                 </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </form>
      </div>
      <b-modal id="editBudget" ref="editBudget" title="Edit Budget" title-tag="h2" centered size="lg" hide-footer>
        <form @submit.prevent="validateBeforeSubmit('edit')" data-vv-scope="edit" class="pb-2" v-if="editBudget">
          <label>{{editBudget.category}} - {{editBudget.date_index | moment('MMM YYYY')}}</label>
          <b-input-group class="mt-3 mb-3">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" v-model="editNumber" v-validate="'required|decimal:2'" name="editNumber" class="form-control" />
          </b-input-group>
          <i v-show="errors.has('edit.editNumber')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('edit.editNumber')" class="help is-danger">The budget number is required. Two decimal points only.</span>

          <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Not authorized to update table. Please contact admin.</span>
          <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
        </form>
      </b-modal>
      <b-modal id="addBudget"  ref="addBudget" title="Add Budget" title-tag="h2" centered size="lg" hide-footer>
        <form data-vv-scope="add" @submit.prevent="addValidateBeforeSubmit('add')" class="pb-2">
          <label>Budget For: {{addDate}}</label>
          <b-input-group class="mt-3 mb-3">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" v-model="addNumber" v-validate="'required|decimal:2'" name="addNumber" class="form-control" />
          </b-input-group>
          <i v-show="errors.has('add.addNumber')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('add.addNumber')" class="help is-danger">The budget number is required.</span>

          <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Not authorized to update table. Please contact admin.</span>
          <b-button variant="success" class="mt-4" type="submit" block>Add</b-button>
        </form>
      </b-modal>
  </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMonthlyPicker from 'vue-monthly-picker';

export default {
  name: 'ManagerBudget',
      components: {
        VueMonthlyPicker
    },
  data: function () {
    return {
      projectData: [],
      catinputs: [],
      catinputss: [],
      projectid: 0,
      error: false,
      nextMonth: null,
      editBudget: [],
      editNumber: 0,
      addNumber: 0,
      addDate: 0,
      addPcid: 0,
      categories: [],
      budgets: [],
      month:null
    }
  },
  methods: {
    sendInfo(budget){
      this.editBudget = budget;
      this.editNumber = this.formatPrice(budget.budget);
    },
    sendAddInfo(date, pcid){
      this.addNumber = 0;
      this.addDate = date;
      this.addPcid = pcid;
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")
    },
    getBudget() {
      this.getCats();
      this.$http.get('/manager/get/budgets/' + this.projectid.projectid)
      .then(response => {
        if (response.status === 200) {
          this.budgets = response.data.result;
          if(this.budgets.length === 0){
            this.nextMonth = this.$moment(this.projectid.p_start_date);
          }
          if(this.budgets.length > 0){
            //change sort order to date asc
            var noSort = this.budgets;

            var date = noSort.sort(function(a,b){
              return new Date(b.date_index) - new Date(a.date_index);
            });
            this.nextMonth = this.$moment(date[0].date_index).add(1, 'M');


            const monthName = item => this.$moment(item.date_index, 'YYYY-MM-DD').format('MMM YYYY');

            const result = this.lodash.mapValues(this.lodash.groupBy(this.budgets, monthName), pcid => this.lodash.sortBy(pcid, "pcid"));
            this.budgets = result;
          }
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    getCats() {
      this.$http.get('/manager/get/cats/' + this.projectid.projectid)
      .then(response => {
        if (response.status === 200) {
          this.categories = this.lodash.sortBy(response.data.result, "pcid");
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    addBudgets(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if(result){
         var date = this.month ? this.$moment(this.month).format('YYYY-MM') : this.$moment(this.nextMonth).format('YYYY-MM');
          const p = "";

          for (var i = 0; i < this.catinputs.length; i++) {
            let data = JSON.stringify({
              projectid: this.projectid.projectid,
              pcid: this.categories[i].pcid,
              date: date,
              budget: this.catinputs[i]
            })

            this.addNewMonth(data, i)
          }
        }
      });
    },
    addNewMonth (data, i) {
      this.$http.post('/manager/create/budget', data).then(response => {
        if (response.status === 200) {
          this.$refs.editBudget.hide();
          this.$refs.addBudget.hide();
          this.getBudget();
        }
      })
      .catch(error => {

      })
    },
    validateBeforeSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.editBudget.bid
          })
          this.$http.post('/manager/delete/budget', data).then(response => {
            let dataC = JSON.stringify({
              projectid: this.projectid.projectid,
              pcid: this.editBudget.pcid,
              date: this.editBudget.date_index,
              budget: this.editNumber
            })
            this.addNewMonth(dataC)
          }).catch(error => {
          })
        }
      })
    },
    addValidateBeforeSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let dataC = JSON.stringify({
            projectid: this.projectid.projectid,
            pcid: this.addPcid,
            date: this.addDate,
            budget: this.addNumber
          })
          this.addNewMonth(dataC)
        }
      })
    },
    loadData() {
      this.$http.get('/manager/get/projects')
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  }
}
</script>

<style lang="scss">
  #editBudget {

  }
  .hover-mouse {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    div {
      padding: 0.25rem !important;
    }
  }
  .hover-mouse:hover {
    cursor: pointer;
    background: rgba(84, 190, 111, 0.3);
  }
  .fa-exclamation-triangle {
    color: #ffc107;
    margin-right: 0.5em;
  }
</style>
